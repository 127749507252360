import React from "react"
import Layout from "../components/Layout"
import BlueBoathouseBGImage from "../components/BlueBoathouseBGImage"
import { Link } from "gatsby"

export default function existingClients() {
  return (
    <Layout>
      <BlueBoathouseBGImage style={{}}>
        <div className="hero-image-canvas"></div>
      </BlueBoathouseBGImage>

      <div className="contents-wrapper">
        <div className="text-container">
          <h1>Existing clients</h1>

          <p>
            To book an appointment please <a href="https://my.powerdiary.com/clientportal/hcpbookings" target="_blank">click here.</a>
          
          </p>
          <p>
            If you need to cancel or rearrange an appointment please{" "}
            <Link to="/contact">contact Sarah</Link> directly by phone or email.
          </p>
        </div>
      </div>
    </Layout>
  )
}
